//component for All Pages Creation

import React from "react"
import { Helmet } from "react-helmet";
import "./all-pages.css"
import Layout from "../../components/note-layout";

const AllPages = ({ brainNotes }) => {
  let notes = [];
  let history = []; 

  if (typeof window !== `undefined` && window){
    history = JSON.parse(window.sessionStorage.history);
  }
  
  console.log("history", history)
  // console.log("all pages values", Object.values(history))
  notes = brainNotes.nodes.map((note) => (
    <>
        <li key={note.id} className="index-item">
          <span className="link-container">
            <a className="link" href={"/notes/"+note.slug}>
            {/* <div className={(Object.values(history).includes(note.title)) || Object.values(history).includes("/notes/" + note.slug) ? "dot visited" : "dot"}> */}
          <div className={history.find(e => e.title === note.title) || history.find( e => e.slug === "/notes/" + note.slug) ? "dot visited" : "dot"}> 
              <div className="hover">{note.title}
                <sup> {note.inboundReferences.length} </sup>
              </div>
            </div>
            
            </a>
          </span>
          {/* <span className="date"> {note.date} </span> */}
        </li>
    </>
  ));

  return (
    <>
    <Helmet>
      <title>
        All Pages
      </title>
    </Helmet>
    <Layout>
      <div id="notes-container"> {notes} </div>
    </Layout>
    </>
    );
};

export default AllPages;
